import baseApi from './base_api.js'

//  查询日记
export function allDaily(options) {
  return baseApi.post('/allDaily', {...options})
}

// 增加日记
export function addDaily(options) {
  return baseApi.post('/addDaily', {...options})
}
