<template>
  <div class="wrap daily_box">
    <!-- <common-nav /> -->
    <div class="weather_box">
      <div class="iframe_box">
        <!-- <iframe scrolling="no" src="https://tianqiapi.com/api.php?style=tb&skin=cherry" frameborder="0" width="400" height="24" allowtransparency="true"></iframe> -->
      </div>
    </div>
    <div class="daily">
      <div class="operation_box">
        <span class="go_date" @click="goToday">回到今天</span>
        <input v-model="goYear" class="year_input" type="text">年
        <input v-model="goMonth" class="month_input" type="text">月
        <span class="go_date" @click="goDate">点我直达</span>
      </div>
      <div class="calendar_box">
        <div class="operation_btn_box iconpageprev_box" @click="prevMonth"><i class="iconfont iconpageprev"></i></div>
        <div class="operation_btn_box iconfenyexiayiye_box" @click="nextMonth"><i class="iconfont iconfenyexiayiye"></i></div>
        <div class="week_box">
          <span class="week_box_item" v-for="(item, index) in week" :key="index">
            {{item}}
          </span>
        </div>
        <div class="day_box">
          <div class="day_item_box">
            <template v-for="(pitem, pindex) in prevMonthDays">
              <div :key="pindex + 100" class="day prev" @click="clickDay(pitem, 0)" v-if="pitem > prevMonthDays-weekDay">
                <div>
                  <div>
                    {{pitem}}
                  </div>
                  <div class="day_content">
                    {{prevContent[pindex].content}}
                  </div>
                </div>
              </div>
            </template>
            <div class="day current" v-for="(citem, cindex) in currentMonthDays" :key="cindex + 200" @click="clickDay(citem, 1)">
              <div :class="{current_day: today.split('-')[2] == citem && today.split('-')[1] == selectMonth && today.split('-')[0] == selectYear, select_day:selectDate.split('-')[2] == citem}">
                <div>
                  {{citem}}
                </div>
                <div class="day_content">
                  {{currentContent[cindex].content}}
                  <span v-show="today.split('-')[2] == citem && today.split('-')[1] == selectMonth && today.split('-')[0] == selectYear && !currentContent[cindex].content">写点什么吧~</span>
                </div>
              </div>
            </div>
            <template v-for="(nitem, nindex) in nextMonthDays">
              <div class="day next" :key="nindex + 300" @click="clickDay(nitem, 2)" v-if="nitem <= 42-currentMonthDays-weekDay">
                <div>
                  <div>
                    {{nitem}}
                  </div>
                  <div class="day_content">
                    {{nextContent[nindex].content}}
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="logo_box">
            <span class="month_logo">{{selectDate.split('-')[1]}}</span>
            <span class="year_logo">{{selectDate.split('-')[0]}}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showWrite" class="daily_input_box">
      <div class="daily_input">
        <div>{{selectDate}}</div>
        <textarea ref="myWord" v-if="!showContent && userId == 41" v-model="dailyContent" class="area" name="" id=""></textarea>
        <textarea v-else v-model="dailyContent" class="area" name="" id="" disabled readonly></textarea>
        <!-- <div v-else class="area">{{dailyContent}}</div> -->
        <div class="daily_operation">
          <div class="cancel_daily" @click="cancelWrite">取消</div>
          <div v-if="userId == 41" class="sure_daily" @click="sureWrite">确认</div>
        </div>
      </div>
    </div>
    <pop ref="goDateBox" :title="finalMes" cancel-text="取消" :show-sure=false @cancel="submitCancel" />
  </div>
</template>

<script>
import pop from '@/component/pop/pop.vue'
// import commonNav from '@/component/commonNav'
import { allDaily, addDaily } from '@/vue_api/daily.js'
export default {
  name: 'daily',
  components: {
    // commonNav,
    pop
  },
  data () {
    return {
      finalMes: '',
      week: [
        '日', '一', '二', '三', '四', '五', '六'
      ],
      weekDay: 0,
      today: new Date().toLocaleDateString().split('/').join('-'),
      selectDate: '',
      selectMonth: '',
      selectYear: '',
      goYear: new Date().toLocaleDateString().split('/')[0],
      goMonth: new Date().toLocaleDateString().split('/')[1],
      currentMonthDays: 0,
      prevMonthDays: 0,
      nextMonthDays: 0,
      showWrite: 0,
      showContent: false,
      dailyList: [],
      prevContent: [],
      currentContent: [],
      nextContent: [],
      dailyContent: '',
      dailyId: null
    }
  },
  mounted () {
    console.log('daily--mounted', this.userName)
    this.getAllDaily()
  },
  methods: {
    // 查询所有content
    showAllContent () {
      this.dailyList.forEach(item => {
        const itemYear = parseInt(item.editTime.split(' ')[0].split('-')[0])
        const itemMonth = parseInt(item.editTime.split(' ')[0].split('-')[1])
        const itemDay = parseInt(item.editTime.split(' ')[0].split('-')[2])
        // console.log('item---', itemMonth - this.selectMonth, itemDay - 1, this.selectYear, this.selectMonth)
        if (this.selectYear == itemYear) {
          if (itemMonth - this.selectMonth == -1) {
            this.prevContent[itemDay - 1].id = item.id || null
            this.prevContent[itemDay - 1].content = item.content || ''
          } else if (itemMonth - this.selectMonth == 0) {
            this.currentContent[itemDay - 1].id = item.id || null
            this.currentContent[itemDay - 1].content = item.content || ''
          } else if (itemMonth - this.selectMonth == 1) {
            this.nextContent[itemDay - 1].id = item.id || null
            this.nextContent[itemDay - 1].content = item.content || ''
          }
        } else {
          console.log('不一样的年------', itemMonth, this.selectMonth, itemMonth - this.selectMonth)
          if (this.selectMonth - itemMonth == -11) {
            // 当前1月，还有去年12月
            this.prevContent[itemDay - 1].id = item.id || null
            this.prevContent[itemDay - 1].content = item.content || ''
          } else if (this.selectMonth - itemMonth == 11) {
            // 当前12月，还有下一年年1月
            this.nextContent[itemDay - 1].id = item.id || null
            this.nextContent[itemDay - 1].content = item.content || ''
          }
        }
      })
    },
    async getAllDaily () {
      await allDaily().then(res => {
        this.dailyList = res.data.dailyList
        this.getMonthDays()
        console.log('dailyList-----', this.dailyList)
      })
    },
    cancelWrite () {
      this.showWrite = 0
    },
    // 新增或编辑当时时间
    formatDateTime (date) {
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      let h = date.getHours()
      h = h < 10 ? ('0' + h) : h
      let minute = date.getMinutes()
      minute = minute < 10 ? ('0' + minute) : minute
      let second = date.getSeconds()
      second = second < 10 ? ('0' + second) : second
      return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
    },
    sureWrite () {
      this.showWrite = 0
      console.log('点击了确定---', this.dailyId)
      if (!this.userName) {
        this.finalMes = '还未登录哦~'
        this.$refs.goDateBox.open()
        return
      } else if (this.userId != 41) {
        this.finalMes = '只有主人才可写哦~'
        this.$refs.goDateBox.open()
        return
      }
      console.log('点击了日期-----', this.dailyId, this.dailyContent, this.selectDate, this.today)
      const params = {
        id: this.dailyId,
        content: this.dailyContent,
        editor: this.userName,
        encryption: 0,
        editTime: this.dailyContent ? this.formatDateTime(new Date(this.selectDate.split('-').join('/'))) : null
      }
      addDaily(params).then(res => {
        console.log(res)
        this.getAllDaily()
      })
    },
    prevMonth () {
      this.selectDate = this.selectMonth !== 1 ? this.selectYear + '-' + (this.selectMonth - 1) + '-' + 1 : (this.selectYear - 1) + '-' + 12 + '-' + 1
      this.getMonthDays(this.selectDate)
    },
    nextMonth () {
      this.selectDate = this.selectMonth !== 12 ? this.selectYear + '-' + (this.selectMonth + 1) + '-' + 1 : (this.selectYear + 1) + '-' + 1 + '-' + 1
      this.getMonthDays(this.selectDate)
    },
    // 弹出框取消
    submitCancel () {
      this.$refs.goDateBox.close()
    },
    // 点我直达
    goDate () {
      let reg = /^[0-9]*$/
      console.log(reg.test(this.goYear), reg.test(this.goMonth))
      if (reg.test(this.goYear) && reg.test(this.goMonth)) {
        if (parseInt(this.goYear) > 1970 && parseInt(this.goYear) <= parseInt(this.today.split('-')[0])) {
          if (parseInt(this.goMonth) > 0 && parseInt(this.goMonth) <= 12) {
            this.selectDate = this.goYear + '-' + this.goMonth + '-' + 1
            this.getMonthDays(this.selectDate)
          } else {
            this.finalMes = '无效的月份查询~'
            this.$refs.goDateBox.open()
          }
        } else {
          this.finalMes = '无效的年份查询~'
          this.$refs.goDateBox.open()
        }
      } else {
        this.finalMes = '无效的日期查询~'
        this.$refs.goDateBox.open()
      }
    },
    goToday () {
      this.getMonthDays(this.today)
    },
    // 选择日期查询数据day: 日期（例25）; val: 0、上月，1、当月，2、下月
    clickDay (day, val) {
      this.dailyId = null
      if (val == 0) {
        this.selectDate = this.selectMonth !== 1 ? this.selectYear + '-' + (this.selectMonth - 1) + '-' + day : (this.selectYear - 1) + '-' + 12 + '-' + day
        this.dailyContent = this.prevContent[day - 1].content
        this.dailyId = this.prevContent[day - 1].id
      } else if (val == 1) {
        this.selectDate = this.selectYear + '-' + this.selectMonth + '-' + day
        this.dailyContent = this.currentContent[day - 1].content
        this.dailyId = this.currentContent[day - 1].id
      } else if (val == 2) {
        this.selectDate = this.selectMonth !== 12 ? this.selectYear + '-' + (this.selectMonth + 1) + '-' + day : (this.selectYear + 1) + '-' + '01' + '-' + day
        this.dailyContent = this.nextContent[day - 1].content
        this.dailyId = this.nextContent[day - 1].id
      }
      console.log('点击了日期-----', this.dailyId, this.dailyContent, this.selectDate, this.today)
      this.showContent = true
      if (new Date(this.selectDate.split('-').join('/')).getTime() <= new Date(this.today.split('-').join('/')).getTime()) {
        this.getMonthDays(this.selectDate)
        this.showWrite = 1
        this.showContent = false
        if (!this.showContent && this.userId == 41) {
          console.log('---------', this.$refs.myWord)
          this.$nextTick(() => {
            this.$refs.myWord.focus()
          })
        }
      }
    },
    // 获取指定日期上个月，当月，下月有几天
    getMonthDays (val, showContentFn = true) {
      this.currentContent = []
      this.prevContent = []
      this.nextContent = []
      this.selectDate = val ? val : this.today  //  yyyy/mm/dd
      this.selectYear = parseInt(this.selectDate.split('-')[0])
      this.selectMonth = parseInt(this.selectDate.split('-')[1])
      this.weekDay = new Date(this.selectYear, this.selectMonth - 1, 1).getDay()
      // const dd = this.today.split('-')[2]
      this.currentMonthDays = new Date(this.selectYear, this.selectMonth, 0).getDate()
      this.prevMonthDays = this.selectMonth !== 1 ? new Date(this.selectYear, this.selectMonth - 1, 0).getDate() : new Date(this.selectYear - 1, 12, 0).getDate()
      this.nextMonthDays = this.selectMonth !== 12 ? new Date(this.selectYear, this.selectMonth + 1, 0).getDate() : new Date(this.selectYear + 1, 1, 0).getDate()
      console.log('today---', this.today, this.currentMonthDays, this.weekDay)
      for (let i = 0; i < this.currentMonthDays; i++) {
        this.currentContent.push({
          id: null,
          content: ''
        })
      }
      for (let i = 0; i < this.prevMonthDays; i++) {
        this.prevContent.push({
          id: null,
          content: ''
        })
      }
      for (let i = 0; i < this.nextMonthDays; i++) {
        this.nextContent.push({
          id: null,
          content: ''
        })
      }
      showContentFn && this.showAllContent()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>