import Vue from 'vue' 
import Axios from 'axios'

const baseAxios = Axios.create({
  baseURL: 'https://zyclen.cn/api/'
  // baseURL: 'http://localhost:8081/'
})

Vue.prototype.$axios = baseAxios

export default baseAxios