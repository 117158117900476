<template>
  <div class="pop" v-show="isShow">
    <div class="big_box">
      <div class="content">
        <div v-if="!forInput" class="no_input">
          {{title}}
        </div>
        <div v-else>
          更改文件名字：
          <input class="file_name" v-model="newFileName" type="text">
        </div>
      </div>
      <div class="button_box">
        <div class="button cancel" :style="{width: showSure ? '2rem' : '4rem'}" @click="cancel">{{cancelText}}</div>
        <div v-show="showSure" class="button sure" @click="sure">{{sureText}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pop',
  data() {
    return {
      isShow: 0,
      newFileName: ''
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    sureText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    showSure: {
      type: Boolean,
      default: true
    },
    forInput: {
      type: Boolean,
      default: false
    },
    fileName: {
      type: String,
      default: ''
    }
  },
  watch: {
    fileName(val) {
      this.newFileName = val
    },
    newFileName(val) {
      this.$emit('update:fileName', val);
    }
  },
  mounted() {
  },
  methods: {
    sure() {
      console.log('点击了确定按钮')
      this.$emit('sure')
    },
    cancel() {
      console.log('点击了取消按钮')
      this.$emit('cancel')
    },
    open() {
      this.isShow = 1
    },
    close() {
      this.isShow = 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss'
</style>